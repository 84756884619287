import fixWebmDuration from 'fix-webm-duration';

export const getSeekableBlob = (inputBlob, duration) => {
    return new Promise((resolve) => {
            fixWebmDuration(inputBlob, duration, { logger: false })
                .then(fixedBlob => {
                    resolve(fixedBlob);
                })
    });
};

export const getFileName = (fileExtension) => {
    const date = new Date();
    const timestamp = date.toISOString().replace(/[:.]/g, '-');
    return `recording-${timestamp}.${fileExtension}`;
};