import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Button, Upload, message, Space, Card, Spin, Dropdown, Menu } from 'antd';
import { UploadOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import config from "../../config";
import '../../styles/App.scss';
import DiskStorage from '../../utils/DiskStorage';
import RecordingModal from './RecordingModal';
import TranscriptionCard from './TranscriptionCard';
import { downloadAsTxt, downloadAsPdf } from '../../utils/DownloadUtils';
import { getSeekableBlob } from '../../utils/TranscribeUtils';

const { Dragger } = Upload;

const Transcribe = () => {
    const { t } = useTranslation();
    const [audioFileList, setAudioFileList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [recordingSettings, setRecordingSettings] = useState({
        fileName: "",
        numSpeakers: 1,
        speakerNames: [],
        recordingType: 'audio'
    });
    const [currentFileName, setCurrentFileName] = useState('');

    const mediaRecorderRef = useRef(null);
    const mediaPartsRef = useRef([]);
    const startTimeRef = useRef(null);
    const audioContextRef = useRef(null);
    const streamRef = useRef(null);

    useEffect(() => {
        navigator.mediaDevices.ondevicechange = handleDeviceChange;

        return () => {
            navigator.mediaDevices.ondevicechange = null;
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, []);

    const handleDeviceChange = async () => {
        if (isRecording) {
            try {
                await updateAudioSources();
            } catch (error) {
                console.error('Error updating audio sources:', error);
                message.error(t('audioSourceUpdateError'));
            }
        }
    };

    const updateAudioSources = async () => {
        if (!streamRef.current) return;

        const newDesktopStream = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: true
        });
        const newVoiceStream = await navigator.mediaDevices.getUserMedia({ audio: true });

        const newTracks = [
            ...newDesktopStream.getVideoTracks(),
            ...mergeAudioStreams(newDesktopStream, newVoiceStream)
        ];

        streamRef.current.getTracks().forEach(track => track.stop());
        newTracks.forEach(track => streamRef.current.addTrack(track));

        // Update the MediaRecorder with the new stream
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current = new MediaRecorder(streamRef.current, {mimeType: 'video/webm; codecs=vp8,opus'});
        setupMediaRecorder();
        mediaRecorderRef.current.start(1000);
    };

    const mergeAudioStreams = (desktopStream, voiceStream) => {
        if (audioContextRef.current) {
            audioContextRef.current.close();
        }
        audioContextRef.current = new AudioContext();
        const destination = audioContextRef.current.createMediaStreamDestination();

        if (desktopStream && desktopStream.getAudioTracks().length > 0) {
            const source1 = audioContextRef.current.createMediaStreamSource(desktopStream);
            const desktopGain = audioContextRef.current.createGain();
            desktopGain.gain.value = 0.7;
            source1.connect(desktopGain).connect(destination);
        }

        if (voiceStream && voiceStream.getAudioTracks().length > 0) {
            const source2 = audioContextRef.current.createMediaStreamSource(voiceStream);
            const voiceGain = audioContextRef.current.createGain();
            voiceGain.gain.value = 0.7;
            source2.connect(voiceGain).connect(destination);
        }

        return destination.stream.getAudioTracks();
    };

    const handleAudioChange = (info) => {
        setAudioFileList(info.fileList);
        if (info.fileList.length > 0) {
            setCurrentFileName(info.fileList[0].name);
        } else {
            setCurrentFileName('');
        }
    };

    const handleAudioSubmit = async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', audioFileList[0].originFileObj);
        try {
            const response = await axios.post(config.transcribe, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                timeout: 600000,
            });
            setApiResponse(response.data);
            message.success(t('fileSubmitSuccess'));
        } catch (error) {
            message.error(t('transcriptionFailed'));
        } finally {
            setIsLoading(false);
        }
    };

    const setupMediaRecorder = () => {
        mediaRecorderRef.current.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
                mediaPartsRef.current.push(event.data);
            }
        };

        mediaRecorderRef.current.onstop = async () => {
            const duration = Date.now() - startTimeRef.current;
            const blob = new Blob(mediaPartsRef.current, {type: 'video/webm'});

            try {
                const fixedBlob = await getSeekableBlob(blob, duration);
                const fileName = `${recordingSettings.fileName}_${getCurrentDate()}.webm`;
                saveRecording(fixedBlob, fileName);
                sendToServer(fixedBlob, fileName);
            } catch (error) {
                console.error('Error processing recording:', error);
                message.error(t('recordingProcessingError'));
            }
        };
    };

    const handleStartRecording = async (newSettings) => {
        setIsModalOpen(false);
        setRecordingSettings(newSettings);
        setCurrentFileName(newSettings.fileName);
        try {
            let desktopStream;
            let voiceStream;

            if (newSettings.recordingType === 'audio') {
                voiceStream = await navigator.mediaDevices.getUserMedia({ audio: true });
                streamRef.current = voiceStream;
            } else {
                desktopStream = await navigator.mediaDevices.getDisplayMedia({
                    video: true,
                    audio: true
                });
                voiceStream = await navigator.mediaDevices.getUserMedia({ audio: true });

                const tracks = [
                    ...desktopStream.getVideoTracks(),
                    ...mergeAudioStreams(desktopStream, voiceStream)
                ];

                streamRef.current = new MediaStream(tracks);
            }

            mediaPartsRef.current = [];
            mediaRecorderRef.current = new MediaRecorder(streamRef.current, {mimeType: 'video/webm; codecs=vp8,opus'});
            setupMediaRecorder();

            mediaRecorderRef.current.start(1000);
            startTimeRef.current = Date.now();
            setIsRecording(true);
        } catch (error) {
            console.error('Error starting recording:', error);
            message.error(t('recordingStartError'));
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }
            setIsRecording(false);
        }
    };

    const getCurrentDate = () => {
        const now = new Date();
        return now.toISOString().slice(0, 10).replace(/-/g, "");
    };

    const saveRecording = (blob, fileName) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        const file = new File([blob], fileName, { type: blob.type });
        DiskStorage.StoreFile(file, (response) => {
            if (response.success) {
                console.log('File stored successfully');
            } else {
                console.error('Failed to store file');
            }
        });
    };

    const sendToServer = async (blob, fileName) => {
        const formData = new FormData();
        formData.append('file', blob, fileName);
        formData.append('fileName', fileName);
        formData.append('numSpeakers', recordingSettings.numSpeakers);
        formData.append('speakerNames', JSON.stringify(recordingSettings.speakerNames));

        setIsLoading(true);
        try {
            const response = await axios.post(config.transcribe, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                timeout: 600000,
            });
            setApiResponse(response.data);
            message.success(t('fileSubmitSuccess'));
        } catch (error) {
            message.error(t('fileSubmitError'));
        } finally {
            setIsLoading(false);
        }
    };

    const getTranscriptFileName = (extension) => {
        const baseName = currentFileName.split('.').slice(0, -1).join('.') || currentFileName;
        return `${baseName}_transcript_${getCurrentDate()}.${extension}`;
    };

    const resetPage = () => {
        stopRecording();
        setAudioFileList([]);
        setApiResponse(null);
    };

    const menu = (
        <Menu>
            <Menu.Item key="txt" icon={<DownloadOutlined />} onClick={() => downloadAsTxt(apiResponse, getTranscriptFileName('txt'))}>
                {t('downloadTxt')}
            </Menu.Item>
            <Menu.Item key="pdf" icon={<DownloadOutlined />} onClick={() => downloadAsPdf(apiResponse, getTranscriptFileName('pdf'))}>
                {t('downloadPdf')}
            </Menu.Item>
        </Menu>
    );

    return (
        <Spin spinning={isLoading}>
            <div className="audio-container">
                <Card title={t('audioAndScreenCapture')}>
                    {apiResponse ? (
                        <>
                            <TranscriptionCard title={t('summary')} content={apiResponse.summary} />
                            <TranscriptionCard title={t('rawTranscript')} content={apiResponse.transcription} />
                            <TranscriptionCard title={t('transcript')} content={apiResponse.diarize_transcription} />
                            <Space direction="horizontal">
                                <Button type="primary" onClick={resetPage}>{t('reset')}</Button>
                                <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                                    <Button type="primary" icon={<DownloadOutlined />}>
                                        {t('downloadAs')}
                                    </Button>
                                </Dropdown>
                            </Space>
                        </>
                    ) : (
                        <Space direction="vertical" size="large" style={{ width: '100%' }}>
                            <Dragger
                                accept="audio/*,video/*"
                                beforeUpload={() => false}
                                onChange={handleAudioChange}
                                fileList={audioFileList}
                            >
                                <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                </p>
                                <p className="ant-upload-text">{t('audioVideoUploadText')}</p>
                            </Dragger>
                            <div className="button-container">
                                <Space>
                                    <Button type="primary" onClick={handleAudioSubmit} disabled={audioFileList.length === 0 || isRecording}>
                                        {t('submitFile')}
                                    </Button>
                                    <Button type="primary" onClick={() => setIsModalOpen(true)} disabled={isRecording || audioFileList.length > 0}>
                                        {isRecording ? <LoadingOutlined spin /> : t('startRecording')}
                                    </Button>
                                    {isRecording && (
                                        <Button type="primary" onClick={stopRecording}>
                                            {t('stopRecording')}
                                        </Button>
                                    )}
                                </Space>
                            </div>
                        </Space>
                    )}
                </Card>
            </div>
            <RecordingModal
                isOpen={isModalOpen}
                onOk={handleStartRecording}
                onCancel={() => setIsModalOpen(false)}
                settings={recordingSettings}
                setSettings={setRecordingSettings}
            />
        </Spin>
    );
};

export default Transcribe;