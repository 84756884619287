import React from 'react';
import { Card, Space, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const TranscriptionCard = ({ title, content }) => (
    <Card
        title={
            <Space className="card-header" justify="space-between">
                <span>{title}</span>
                <CopyToClipboard text={content}>
                    <Button size="small" type="text" icon={<CopyOutlined />} />
                </CopyToClipboard>
            </Space>
        }
        className="card transcript-card"
    >
        <div className="transcription" dangerouslySetInnerHTML={{ __html: content }} />
    </Card>
);

export default TranscriptionCard;