import React, { useState, useEffect } from 'react';
import { Input, Button, Upload, Space, Progress, message } from 'antd';
import { SendOutlined, PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ReadyState } from 'react-use-websocket';

const { TextArea } = Input;

const CHUNK_SIZE = 64 * 1024; // 64KB chunks

const MessageInput = ({
                          handleSendClick,
                          readyState,
                          sendMessage,
                          userId,
                          onFileUploadStart,
                          onFileUploadComplete,
                          deleteFile,
                          isProcessing
                      }) => {
    const { t } = useTranslation();
    const [messageContent, setMessageContent] = useState('');
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileUploadPending, setFileUploadPending] = useState(false);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        const fileTypes = {
            // Document types
            pdf: 'application/pdf',
            doc: 'application/msword',
            docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            xls: 'application/vnd.ms-excel',
            xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            txt: 'text/plain',

            // Image types
            png: 'image/png',
            jpg: 'image/jpeg',
            jpeg: 'image/jpeg',
            gif: 'image/gif',
            svg: 'image/svg+xml',
            webp: 'image/webp',

            // Audio types
            mp3: 'audio/mpeg',
            wav: 'audio/wav',
            ogg: 'audio/ogg',
            m4a: 'audio/m4a',

            // Video types
            mp4: 'video/mp4',
            webm: 'video/webm',
            avi: 'video/x-msvideo',
            mov: 'video/quicktime',
            wmv: 'video/x-ms-wmv',
            flv: 'video/x-flv',
            mkv: 'video/x-matroska'
        };
        return fileTypes[extension] || 'application/octet-stream';
    };

    const sendFileInChunks = (file) => {
        let offset = 0;
        const fileReader = new FileReader();
        const fileSize = file.size;
        const fileType = getFileType(file.name);

        const readNextChunk = () => {
            const slice = file.slice(offset, offset + CHUNK_SIZE);
            fileReader.readAsArrayBuffer(slice);
        };

        fileReader.onload = (e) => {
            if (!e.target.result) return;

            const chunk = new Uint8Array(e.target.result);
            sendMessage(JSON.stringify({
                type: 'file_chunk',
                file_name: file.name,
                chunk: Array.from(chunk),
                offset: offset,
                total: fileSize,
                user_id: userId,
                file_type: fileType
            }));

            offset += chunk.length;
            const progress = Math.min(100, Math.round((offset / fileSize) * 100));
            setUploadProgress(prev => ({ ...prev, [file.name]: progress }));

            if (offset < fileSize) {
                readNextChunk();
            } else {
                sendMessage(JSON.stringify({
                    type: 'file_complete',
                    file_name: file.name,
                    user_id: userId,
                    file_type: fileType
                }));
            }
        };

        readNextChunk();
    };

    const handleFileUpload = (file) => {
        const maxSize = 100 * 1024 * 1024; // 100 MB
        if (file.size > maxSize) {
            message.error('File size exceeds 100 MB limit.');
            return false;
        }

        if (!fileUploadPending) {
            onFileUploadStart();
            setFileUploadPending(true);
        }

        setUploadingFiles(prev => [...prev, file]);
        setUploadProgress(prev => ({ ...prev, [file.name]: 0 }));
        sendFileInChunks(file);
        return false; // Prevent default upload
    };

    const handleSendMessage = () => {
        if (messageContent.trim() || uploadedFiles.length > 0) {
            handleSendClick({
                message: messageContent,
                fileKeys: uploadedFiles.map(file => file.fileKey)
            });
            setMessageContent('');
            setUploadedFiles([]);
            setFileUploadPending(false);
        } else if (!fileUploadPending) {
            message.error("Message cannot be empty!");
        }
    };

    const handleDeleteFile = (fileKey) => {
        deleteFile(fileKey);
        setUploadedFiles(prev => prev.filter(file => file.fileKey !== fileKey));
    };

    useEffect(() => {
        const handleWebSocketMessage = (event) => {
            let data;
            try {
                if (typeof event.data === 'object') {
                    data = event.data;
                } else {
                    data = JSON.parse(event.data);
                }

                if (data.type === 'file_upload_complete' && data.file_key) {
                    const uploadedFile = uploadingFiles.find(file => file.name === data.file_key.split('/').pop());
                    if (uploadedFile) {
                        setUploadedFiles(prev => [...prev, { fileKey: data.file_key, fileName: uploadedFile.name }]);
                        setUploadingFiles(prev => prev.filter(file => file.name !== uploadedFile.name));
                        setUploadProgress(prev => {
                            const newProgress = { ...prev };
                            delete newProgress[uploadedFile.name];
                            return newProgress;
                        });

                        onFileUploadComplete(data.file_key);

                        // Remove progress bar after a few seconds
                        setTimeout(() => {
                            setUploadProgress(prev => {
                                const newProgress = { ...prev };
                                delete newProgress[uploadedFile.name];
                                return newProgress;
                            });
                        }, 3000);
                    }
                }
            } catch (error) {
                console.error('Error processing WebSocket message:', error);
                console.log('Raw message:', event.data);
                message.error('Error processing server response');
            }
        };

        window.addEventListener('message', handleWebSocketMessage);

        return () => {
            window.removeEventListener('message', handleWebSocketMessage);
        };
    }, [uploadingFiles, onFileUploadComplete]);

    const connectionStatusColor = () => {
        switch (readyState) {
            case ReadyState.OPEN:
                return 'green';
            case ReadyState.CONNECTING:
                return 'orange';
            case ReadyState.CLOSED:
            case ReadyState.UNINSTANTIATED:
            default:
                return 'red';
        }
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <div style={{ position: 'relative' }}>
                <TextArea
                    value={messageContent}
                    onChange={(e) => setMessageContent(e.target.value)}
                    onKeyDown={handleKeyDown}
                    rows={4}
                    placeholder={uploadingFiles.length > 0 ? `Uploading ${uploadingFiles.length} file(s)...` : t('TYPE_MESSAGE')}
                    disabled={isProcessing}
                />
            </div>
            <div>
                {Object.entries(uploadProgress).map(([fileName, progress]) => (
                    <div key={fileName} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <Progress
                            type="circle"
                            percent={progress}
                            width={20}
                            status="success"
                            style={{ marginRight: '10px' }}
                        />
                        <span>{fileName}</span>
                    </div>
                ))}
            </div>
            <div>
                {uploadedFiles.map(file => (
                    <div key={file.fileKey} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <span>{file.fileName}</span>
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={() => handleDeleteFile(file.fileKey)}
                            style={{ marginLeft: '10px' }}
                        />
                    </div>
                ))}
            </div>
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                <Space>
                    <Upload
                        beforeUpload={handleFileUpload}
                        showUploadList={false}
                        multiple={true}
                        className="interactive-agent-upload"
                        disabled={isProcessing}
                    >
                        <Button icon={<PaperClipOutlined />} disabled={isProcessing}>
                            {t('ATTACH_FILES')}
                        </Button>
                    </Upload>
                    <Button
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={handleSendMessage}
                        disabled={isProcessing || (!messageContent.trim() && uploadedFiles.length === 0 && !fileUploadPending)}
                    >
                        {t('SEND')}
                    </Button>
                </Space>
                <Space className="connection-status">
                    <div
                        className="status-indicator"
                        style={{
                            backgroundColor: connectionStatusColor(),
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%'
                        }}
                    />
                    {t('CONNECTION_STATUS')}
                </Space>
            </Space>
        </Space>
    );
};

export default MessageInput;
