import React from 'react';
import { Card, Dropdown, Menu, Button } from 'antd';
import { PlusOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { useDrag, useDrop } from 'react-dnd';
import axios from 'axios';
import config from "../../config";
import '../../styles/App.scss';
import { useTranslation } from 'react-i18next';

// Function to get color based on level
const getColorByLevel = (level) => {
    const colors = [
        '#a3c4f3',
        '#90dbf4',
        '#8eecf5',
        '#98f5e1',
        '#b9fbc0',
        '#fbf8cc',
        '#fde4cf',
        '#ffcfd2',
        '#f1c0e8',
        '#cfbaf0',
    ];
    return colors[level % colors.length];
};

const OrganizationNode = ({ org, onAddSubOrg, onUpdateOrg, level, onRefresh }) => {
    const { t } = useTranslation();

    const [{ isDragging }, drag] = useDrag({
        type: 'organization',
        item: { id: org.id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'organization',
        drop: async (item) => {
            if (item.id !== org.id) {
                console.log(`Dropped ${item.id} into ${org.id}`);
                try {
                    // Call API to update parent-child relationship
                    await axios.patch(`${config.organisationUrl}${item.id}/`, {
                        parent: org.id,
                    });
                    console.log('Parent-child update successful');
                    onRefresh(); // Ensure refresh is called after the drop
                } catch (error) {
                    console.error('Parent-child update failed', error);
                }
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = canDrop && isOver;
    let backgroundColor = getColorByLevel(level);
    if (isActive) backgroundColor = '#ddffd2';

    const levelClass = `level${level}`;

    const menu = (
        <Menu>
            <Menu.Item icon={<PlusOutlined />} onClick={() => onAddSubOrg(org)}>
                {t('org.addChild')}
            </Menu.Item>
            <Menu.Item icon={<EditOutlined />} onClick={() => onUpdateOrg(org)}>
                {t('update')}
            </Menu.Item>
        </Menu>
    );

    return (
        <Card
            ref={(node) => drag(drop(node))}
            style={{ opacity: isDragging ? 0.5 : 1, backgroundColor }}
            className={`org-node ${levelClass}`}
        >
            <div style={{ textAlign: 'right' }}>
                <Dropdown overlay={menu} trigger={['click']}>
                    <Button icon={<MoreOutlined />} shape="circle" size="small" />
                </Dropdown>
            </div>
            <div className="org-node-content">
                <h4>{org.name}</h4>
                <p>{org.organisation_type}</p>
                {org.plans && <p>{org.plans} {t('org.plans')}</p>}
            </div>
        </Card>
    );
};

export default OrganizationNode;
