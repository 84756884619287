import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GoogleOAuthProvider} from "@react-oauth/google";
import './i18n/i18n';
import {ConfigProvider} from "antd";


//client secret : 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
ReactDOM.createRoot(document.getElementById('root')).render(
    <GoogleOAuthProvider clientId='426704023816-7073b8v6o7vq1ho0asp0fh87un5drvia.apps.googleusercontent.com'>
        <React.StrictMode>
            <ConfigProvider theme={{ hashed: false }}>
                <App />
            </ConfigProvider>
        </React.StrictMode>,
    </GoogleOAuthProvider>
)

reportWebVitals();
