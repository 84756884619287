import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import OrganizationNode from './OrganizationNode';

const OrganizationTree = ({ data, onAddSubOrg, onUpdateOrg, onRefresh }) => {
    const renderTree = (node, level = 1) => (
        <TreeNode
            key={node.id}
            label={
                <OrganizationNode
                    org={node}
                    onAddSubOrg={onAddSubOrg}
                    onUpdateOrg={onUpdateOrg}
                    level={level}
                    onRefresh={onRefresh}
                />
            }
        >
            {node.sub_organisations && node.sub_organisations.length > 0
                ? node.sub_organisations.map((child) => renderTree(child, level + 1)) // Increment level for each child
                : null}
        </TreeNode>
    );

    return (
        <Tree
            lineWidth={"2px"}
            lineColor={"#bbc"}
            lineBorderRadius={"12px"}
            label={
                <OrganizationNode
                    org={data}
                    onAddSubOrg={onAddSubOrg}
                    onUpdateOrg={onUpdateOrg}
                    level={1}
                    onRefresh={onRefresh}
                />
            }
        >
            {data.sub_organisations && data.sub_organisations.length > 0
                ? data.sub_organisations.map((child) => renderTree(child, 2)) // Start children from level 2
                : null}
        </Tree>
    );
};

export default OrganizationTree;
