import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown, Button } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';

const LanguageSwitcher = () => {
    const { t, i18n } = useTranslation();

    const languageItems = [
        { key: 'en', label: t('language.english') },
        { key: 'zh', label: t('language.chinese') },
        { key: 'es', label: t('language.spanish') },
        { key: 'ar', label: t('language.arabic') },
    ];

    const handleChangeLanguage = ({ key }) => {
        i18n.changeLanguage(key).then(() => {
            console.log(`Language changed to: ${key}`);
        });
    };

    const menu = (
        <Menu onClick={handleChangeLanguage}>
            {languageItems.map(({ key, label }) => (
                <Menu.Item key={key}>
                    {label}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown overlay={menu}>
            <Button>
                <GlobalOutlined />
            </Button>
        </Dropdown>
    );
};

export default LanguageSwitcher;
