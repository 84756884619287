import { jsPDF } from "jspdf";
import { devanagari } from '../fonts/devanagari-normal.js';

export const downloadAsTxt = (apiResponse, fileName) => {
    const combinedText = `Summary:\n\n${apiResponse.summary}\n\nTranscription:\n\n${apiResponse.transcription}\n\nDiarized Transcription:\n\n${stripHtml(apiResponse.diarize_transcription)}`;
    const blob = new Blob([combinedText], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'transcript.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};

export const downloadAsPdf = (apiResponse, fileName) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 12;
    const maxLineWidth = pageWidth - margin * 2;
    const lineHeight = 8;
    const sectionSpacing = 20;

    // Embed fonts
    doc.addFileToVFS('Devanagari.ttf', devanagari);
    doc.addFont('Devanagari.ttf', 'Devanagari', 'normal');

    const wrapText = (text, x, y) => {
        const lines = doc.splitTextToSize(text, maxLineWidth);
        lines.forEach(line => {
            if (y + lineHeight > pageHeight - margin) {
                doc.addPage();
                y = margin;
            }
            doc.text(line, x, y);
            y += lineHeight;
        });
        return y;
    };

    const addHeader = (title, x, y) => {
        doc.setFillColor(240, 240, 240);
        doc.rect(x - 2, y - 8, maxLineWidth + 4, 12, 'F');
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text(title, x, y);
        y += lineHeight + 2;
        doc.setFontSize(12);
        doc.setFont("Devanagari", "normal");
        return y;
    };

    let y = margin;

    y = addHeader("Summary", margin, y);
    y = wrapText(apiResponse.summary, margin, y);
    y += sectionSpacing;

    y = addHeader("Transcription", margin, y);
    y = wrapText(apiResponse.transcription, margin, y);
    y += sectionSpacing;

    y = addHeader("Diarized Transcription", margin, y);
    wrapText(stripHtml(apiResponse.diarize_transcription), margin, y);

    doc.save(fileName || "transcript.pdf");
};

const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
};