/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Form, Select, Button, Upload, Typography, Spin, Card, Row, Col } from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import config from '../config';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;
const { Dragger } = Upload;

const AnalyseSkills = ({ profile }) => {
    const [sectors, setSectors] = useState([]);
    const [currentJobRoles, setCurrentJobRoles] = useState([]);
    const [desiredJobRoles, setDesiredJobRoles] = useState([]);
    const [fileList, setFileList] = useState([]);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(config.sectorUrl)
            .then(response => setSectors(response.data))
            .catch(error => console.error("Error fetching sectors: ", error));
    }, []);

    const fetchJobRoles = async (sectorId, setJobRolesFn) => {
        try {
            const response = await axios.get(`${config.jobRolesUrl}&sector_id=${sectorId}`);
            setJobRolesFn(response.data);
        } catch (error) {
            console.error("Error fetching job roles: ", error);
        }
    };

    const handleSectorChange = (value, setJobRolesFn) => {
        fetchJobRoles(value, setJobRolesFn);
    };

    const handleUpload = ({ fileList }) => setFileList(fileList);

    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: ".pdf,.doc,.docx,.txt",
        onRemove: file => {
            setFileList(fileList.filter(item => item !== file));
        },
        beforeUpload: file => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                setFileList(info.fileList);
            } else if (status === 'error') {
                console.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const handleOnSubmit = async (values) => {
        try {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('current_job_role', values.currentJobRole);
            formData.append('desired_job_role', values.desiredJobRole);
            formData.append('current_sector', values.currentSector);
            formData.append('desired_sector', values.desiredSector);
            formData.append("user_id", profile.id);

            if (fileList[0]) {
                formData.append('cv', fileList[0]);
            }

            const result = await axios.post(config.skillsAnalysisUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });

            console.log(result.data);
            navigate('/skills-gap', { state: { data: result.data } });

        } catch (error) {
            console.error("Error on form submit: ", error.response);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Spin spinning={isLoading}>
            <div className="profile-container">
                <Title level={2}>{t('profile')}</Title>
                <Form layout="vertical" onFinish={handleOnSubmit}>
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Card title={t('current_status')} bordered={false}>
                                <Form.Item label={t('current_sector')} name="currentSector" rules={[{ required: true, message: t('m_select_current_sector') }]}>
                                    <Select
                                        className="select-max-width"
                                        showSearch
                                        placeholder={t('p_select_current_sector')}
                                        onChange={(value) => handleSectorChange(value, setCurrentJobRoles)}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {sectors.map((sector, index) => (
                                            <Option key={index} value={sector.id}>{sector.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label={t('current_job_role')} name="currentJobRole" rules={[{ required: true, message: t('m_select_current_role') }]}>
                                    <Select
                                        className="select-max-width"
                                        showSearch
                                        placeholder={t('p_select_current_role')}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {currentJobRoles.map((role, index) => (
                                            <Option key={index} value={role.id}>{role.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label={t('upload_cv')}>
                                    <Dragger {...uploadProps}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">Support for PDF, DOCX, DOC, and TXT files.</p>
                                    </Dragger>
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col xs={24} md={12}>
                            <Card title={t('desired_status')} bordered={false}>
                                <Form.Item label={t('desired_sector')} name="desiredSector" rules={[{ required: true, message: t('m_select_desired_sector') }]}>
                                    <Select
                                        className="select-max-width"
                                        showSearch
                                        placeholder={t('p_select_desired_sector')}
                                        onChange={(value) => handleSectorChange(value, setDesiredJobRoles)}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {sectors.map((sector, index) => (
                                            <Option key={index} value={sector.id}>{sector.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label={t('desired_job_role')} name="desiredJobRole" rules={[{ required: true, message: t('m_select_desired_role') }]}>
                                    <Select
                                        className="select-max-width"
                                        showSearch
                                        placeholder={t('p_select_desired_role')}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {desiredJobRoles.map((role, index) => (
                                            <Option key={index} value={role.id}>{role.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                    <div className="button-container" style={{ marginTop: '20px' }}>
                        <Button type="primary" htmlType="submit">{t('submit')}</Button>
                    </div>
                </Form>
            </div>
        </Spin>
    );
};

export default AnalyseSkills;
