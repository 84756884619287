import React from 'react';
import { Layout } from 'antd';
import { FrownOutlined } from '@ant-design/icons';

const { Content } = Layout;

const NotFound = () => {
    return (
        <Content className="not-found-content">
            <div className="not-found-container">
                <FrownOutlined className="not-found-icon" />
                <h1 className="not-found-title">Page Not Found</h1>
                <p className="not-found-description">
                    The page you are looking for does not exist.
                </p>
            </div>
        </Content>
    );
};

export default NotFound;
