import React from 'react';
import { Modal, Form, Input, Radio, InputNumber } from 'antd';
import { AudioOutlined, DesktopOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

const RecordingModal = ({ isOpen, onOk, onCancel, settings, setSettings }) => {
    const { t } = useTranslation();

    const handleSettingsChange = (changedValues) => {
        setSettings({ ...settings, ...changedValues });
    };

    const handleOk = () => {
        onOk(settings);
    };

    return (
        <Modal
            title={t('recordingDetails')}
            open={isOpen}
            onOk={handleOk}
            onCancel={onCancel}
            okText={t('startRecording')}
        >
            <Form
                layout="vertical"
                initialValues={settings}
                onValuesChange={handleSettingsChange}
            >
                <Form.Item
                    name="fileName"
                    label={t('fileName')}
                    rules={[{ required: true, message: t('fileNameRequired') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="recordingType"
                    label={t('recordingType')}
                    rules={[{ required: true, message: t('recordingTypeRequired') }]}
                >
                    <Radio.Group>
                        <Radio value="audio"><AudioOutlined /> {t('audioOnly')}</Radio>
                        <Radio value="screen"><DesktopOutlined /> {t('screenAndAudio')}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="numSpeakers"
                    label={t('numberOfSpeakers')}
                    rules={[{ required: true, message: t('numSpeakersRequired') }]}
                >
                    <InputNumber min={1} max={10} />
                </Form.Item>
                {Array.from({ length: settings.numSpeakers }).map((_, i) => (
                    <Form.Item
                        key={i}
                        name={['speakerNames', i]}
                        label={`${t('speakerName')} ${i + 1}`}
                        rules={[{ required: true, message: t('speakerNameRequired') }]}
                    >
                        <Input />
                    </Form.Item>
                ))}
            </Form>
        </Modal>
    );
};

export default RecordingModal;