import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Select, InputNumber, Button, Form, message, Upload, Spin, Card} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../config';
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CreateAssessmentPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [sector, setSector] = useState('');
    const [jobRole, setJobRole] = useState('');
    const [skills, setSkills] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [jobRoles, setJobRoles] = useState([]);
    const [availableSkills, setAvailableSkills] = useState([]);
    const [numQuestions, setNumQuestions] = useState(1);
    const [jobDescriptionFile, setJobDescriptionFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        axios.get(config.sectorUrl)
            .then(response => setSectors(response.data))
            .catch(error => console.error('Error fetching sectors: ', error));
    }, []);

    useEffect(() => {
        if (location.state) {
            const { industry, jobRole: initialJobRole, competency } = location.state;
            setSector(industry);
            form.setFieldsValue({ sector: industry });
            fetchJobRoles(industry, initialJobRole, competency);
        }
    }, [location.state, form]);

    const fetchJobRoles = async (industry, initialJobRole, competency) => {
        try {
            const response = await axios.get(`${config.jobRolesUrl}&sector_id=${industry}`);
            setJobRoles(response.data);
            if (initialJobRole) {
                setJobRole(initialJobRole);
                form.setFieldsValue({ jobRole: initialJobRole });
                fetchSkills(initialJobRole, competency);
            }
        } catch (error) {
            console.error('Error fetching job roles: ', error);
        }
    };

    const fetchSkills = async (jobRole, competency) => {
        try {
            const response = await axios.get(`${config.competenciesUrl}&job_role_id=${jobRole}`);
            setAvailableSkills(response.data);
            if (competency) {
                const selectedSkills = response.data.filter(skill => competency.includes(skill.title));
                setSkills(selectedSkills.map(skill => ({ id: skill.id, proficiency_id: skill.proficiency_id })));
                form.setFieldsValue({ skills: selectedSkills.map(skill => skill.id) });
            }
        } catch (error) {
            console.error('Error fetching skills: ', error);
        }
    };

    const onSectorChange = async (value) => {
        setSector(value);
        form.resetFields(['jobRole', 'skills']);
        try {
            const response = await axios.get(`${config.jobRolesUrl}&sector_id=${value}`);
            setJobRoles(response.data);
        } catch (error) {
            console.error('Error fetching job roles: ', error);
        }
    };

    const onJobRoleChange = async (value) => {
        setJobRole(value);
        form.resetFields(['skills']);
        try {
            const response = await axios.get(`${config.competenciesUrl}&job_role_id=${value}`);
            setAvailableSkills(response.data);
        } catch (error) {
            console.error('Error fetching skills: ', error);
        }
    };

    const onSkillsChange = (selectedSkills) => {
        const updatedSkills = selectedSkills.map(skillId => {
            const skill = availableSkills.find(s => s.id === skillId);
            return skill ? { id: skill.id, proficiency_id: skill.proficiency_id } : { id: skillId, proficiency_id: null };
        });

        setSkills(updatedSkills);
    };

    const onNumQuestionsChange = (value) => {
        setNumQuestions(value);
    };

    const onJobDescriptionFileChange = info => {
        setJobDescriptionFile(info.file);
    };

    const propsUpload = {
        beforeUpload: file => {
            setJobDescriptionFile(file);
            return false;
        },
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const formData = new FormData();

        formData.append('sector_id', sector);
        formData.append('job_role_id', jobRole);
        formData.append('skills', JSON.stringify(skills));
        formData.append('num_questions', numQuestions);

        if (jobDescriptionFile) {
            formData.append('job_description_file', jobDescriptionFile);
        }

        try {
            const response = await axios.post(config.assessmentsUrl, formData);
            message.success('Assessment created successfully');
            form.resetFields();
            navigate('/review-assessment-questions', { state: { data: response.data } });
        } catch (error) {
            console.error('Error creating assessment:', error);
            message.error('Failed to create assessment');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Spin spinning={isLoading}>
            <div className="create-assessment-container">
                <Card title={t('create_assessment')}>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Form.Item label={t('sector')} name="sector" rules={[{ required: true, message: t('please_select_a_sector') }]}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())}
                            placeholder={t('sector')}
                            onChange={onSectorChange}
                        >
                            {sectors.map((sector) => (
                                <Option key={sector.id} value={sector.id}>{sector.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={t('job_role')} name="jobRole" rules={[{ required: true, message: t('please_select_a_job_role') }]}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())}
                            placeholder={t('job_role')}
                            onChange={onJobRoleChange}
                        >
                            {jobRoles.map((role) => (
                                <Option key={role.id} value={role.id}>{role.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={t('skills')} name="skills" rules={[{ required: true, message: t('please_select_at_least_one_skill') }]}>
                        <Select
                            mode="multiple"
                            placeholder={t('skills')}
                            onChange={onSkillsChange}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {availableSkills.map((skill) => (
                                <Option key={skill.id} value={skill.id}>
                                    {skill.title}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={t('number_of_questions')} name="numQuestions" rules={[{ required: true, message: t('please_specify_the_number_of_questions') }]}>
                        <InputNumber min={1} max={100} onChange={onNumQuestionsChange} value={numQuestions} />
                    </Form.Item>
                    <Form.Item label={t('upload_job_description')} name="jobDescription">
                        <Upload {...propsUpload} onChange={onJobDescriptionFileChange}>
                            <Button icon={<UploadOutlined />}>{t('upload_job_description')}</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">{t('generate_questions')}</Button>
                    </Form.Item>
                </Form>
            </Card>
            </div>
        </Spin>
    );
};

export default CreateAssessmentPage;
