import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Dropdown, Menu, message, Modal, Spin, Switch, Table, Form, Input, Empty } from 'antd';
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import config from "../../config";
import { useTranslation } from 'react-i18next';
import OrganizationTree from './OrganizationTree';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../../styles/App.scss';

const { TextArea } = Input;

// Helper function to build tree structure from flat data
const buildTree = (flatData) => {
    const idMap = {};
    flatData.forEach(org => {
        idMap[org.id] = { ...org, sub_organisations: [] };
    });

    const tree = [];
    flatData.forEach(org => {
        if (org.parent === null) {
            tree.push(idMap[org.id]); // Root organization (no parent)
        } else if (idMap[org.parent]) {
            idMap[org.parent].sub_organisations.push(idMap[org.id]); // Link to parent
        }
    });

    return tree; // Return the root node(s) of the tree
};

const Organisation = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [flatOrganisations, setFlatOrganisations] = useState([]);
    const [treeOrganisations, setTreeOrganisations] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [parentOrg, setParentOrg] = useState(null);
    const [isTreeView, setIsTreeView] = useState(true);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchOrganisations();
    }, []);

    // Fetch the organizations and store both flat and tree structures
    const fetchOrganisations = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${config.organisationUrl}`);
            const flatData = response.data.results;
            setFlatOrganisations(flatData); // Set flat data for table view
            const treeData = buildTree(flatData); // Build the tree structure from flat data
            setTreeOrganisations(treeData); // Set tree data for tree view
        } catch (error) {
            message.error(t('org.errorFetchingOrganisations'));
        } finally {
            setLoading(false);
        }
    };

    const handleAddOrganisation = async (values) => {
        const apiMethod = selectedOrg ? 'patch' : 'post';
        const url = selectedOrg
            ? `${config.organisationUrl}${selectedOrg.id}/`
            : `${config.organisationUrl}`;

        try {
            await axios[apiMethod](url, {
                ...values,
                parent: parentOrg ? parentOrg.id : null, // Set parent ID based on selected org for add
            });
            message.success(t(selectedOrg ? 'organisationUpdated' : 'organisationAdded'));
            setModalVisible(false);
            form.resetFields();
            fetchOrganisations(); // Refresh data after the add or update
        } catch (error) {
            message.error(t('org.errorUpdatingOrganisation'));
        }
    };

    // Open modal for adding a child node
    const openAddSubOrgModal = (parentOrg) => {
        setParentOrg(parentOrg); // Set current node as parent for new child
        setSelectedOrg(null); // Clear selected org (since this is for adding a new org)
        form.resetFields();
        setModalVisible(true);
    };

    // Open modal for updating an existing node
    const openUpdateOrgModal = (org) => {
        setSelectedOrg(org); // Set the org to be updated
        setParentOrg(null); // Clear the parent org (since this is for update)
        form.setFieldsValue(org); // Prepopulate form with org details
        setModalVisible(true);
    };

    const handleUpdateOrganisation = async (values) => {
        const parentId = selectedOrg?.parent ? selectedOrg.parent : null;

        try {
            await axios.patch(`${config.organisationUrl}${selectedOrg.id}/`, {
                ...values,
                parent: parentId, // Keep the parentId for non-root nodes
            });
            message.success(t('org.organisationUpdated'));
            setModalVisible(false);
            form.resetFields();
            fetchOrganisations(); // Refresh data after update
        } catch (error) {
            message.error(t('org.errorUpdatingOrganisation'));
        }
    };

    const menu = (record) => (
        <Menu>
            <Menu.Item
                icon={<PlusOutlined />}
                onClick={() => openAddSubOrgModal(record)}
            >
                {t('org.addChild')}
            </Menu.Item>
            <Menu.Item
                icon={<MoreOutlined />}
                onClick={() => openUpdateOrgModal(record)}
            >
                {t('update')}
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: t('org.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('org.type'),
            dataIndex: 'organisation_type',
            key: 'organisation_type',
        },
        {
            title: t('org.description'),
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
        },
        {
            title: t('org.actions'),
            key: 'actions',
            render: (text, record) => (
                <Dropdown overlay={menu(record)} trigger={['click']}>
                    <Button icon={<MoreOutlined />} />
                </Dropdown>
            ),
        }
    ];

    return (
        <Spin spinning={loading}>
            <Card
                title={t('org.organisations')}
                extra={
                    <>
                        <Switch
                            checked={isTreeView}
                            onChange={() => setIsTreeView(!isTreeView)}
                            checkedChildren={t('org.treeView')}
                            unCheckedChildren={t('org.tableView')}
                            style={{ marginRight: '16px' }}
                        />
                    </>
                }
            >
                <div className="organisation-container">
                    {flatOrganisations.length > 0 ? (
                        isTreeView ? (
                            <DndProvider backend={HTML5Backend}>
                                {treeOrganisations.length > 0 && (
                                    <OrganizationTree
                                        data={treeOrganisations[0]} // Render from the root node
                                        onAddSubOrg={openAddSubOrgModal}
                                        onUpdateOrg={openUpdateOrgModal}
                                        onRefresh={fetchOrganisations} // Pass refresh function to refresh data
                                    />
                                )}
                            </DndProvider>
                        ) : (
                            <Table
                                dataSource={flatOrganisations} // Use flat data for the table
                                columns={columns}
                                rowKey="id"
                                pagination={{ pageSize: 10 }}
                            />
                        )
                    ) : (
                        <Empty description={t('org.noOrganisations')}>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => openAddSubOrgModal(null)} // For top-level org (parent = null)
                            >
                                {t('org.addRootOrganisation')}
                            </Button>
                        </Empty>
                    )}
                </div>
            </Card>

            <Modal
                title={selectedOrg ? `${t('org.updateOrganisation')}` : t('org.addOrganisation')}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
            >
                <Form
                    form={form}
                    onFinish={selectedOrg ? handleUpdateOrganisation : handleAddOrganisation}
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        label={t('org.name')}
                        rules={[{ required: true, message: t('org.pleaseEnterName') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="organisation_type" label={t('org.type')}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label={t('org.description')}>
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t('submit')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Spin>
    );
};

export default Organisation;
