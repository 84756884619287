import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Checkbox, Col, List, message, Progress, Row, Spin, Tabs, Tooltip, Typography } from 'antd';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import config from '../config';
import '../styles/App.scss';
import { useTranslation } from 'react-i18next';

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

const proficiencyLevelsTechnical = {
    1: 10,
    2: 25,
    3: 50,
    4: 75,
    5: 90,
    6: 100
};

const proficiencyLevelsSoft = {
    "None": 0,
    "Basic": 33,
    "Intermediate": 66,
    "Advanced": 100
};

const getSkillsScoreIndicator = (score) => {
    if (score >= 90) return { text: 'Excellent', color: '#52c41a' }; // Green
    if (score >= 75) return { text: 'Good', color: '#1890ff' }; // Blue
    if (score >= 50) return { text: 'Average', color: '#faad14' }; // Yellow
    return { text: 'Needs Improvement', color: '#f5222d' }; // Red
};

const SkillGap = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [completedItems, setCompletedItems] = useState(new Set());
    const [skillAnalysisData, setSkillAnalysisData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const triggerSkillGapAnalysis = async (profileId, type) => {
            try {
                setLoading(true);
                const profileUrl = config.profileUrl.replace('{ext_user_id}', profileId);
                const profileResponse = await axios.get(profileUrl);

                const profileData = profileResponse.data;
                const currentJobRole = profileData.current_job_role.id;
                const currentSector = profileData.current_job_role.sector;
                const desiredJobRole = profileData.desired_job_role.id;
                const desiredSector = profileData.desired_job_role.sector;

                const formData = new FormData();
                formData.append('current_job_role', currentJobRole);
                formData.append('current_sector', currentSector);
                if (type === 'desired') {
                    formData.append('desired_job_role', desiredJobRole);
                    formData.append('desired_sector', desiredSector);
                }
                formData.append('user_id', profileId);

                const analysisResponse = await axios.post(config.skillsAnalysisUrl, formData);
                setSkillAnalysisData(analysisResponse.data.skill_analysis);

            } catch (error) {
                console.error("Error during skill gap analysis:", error);
                message.error(t('skill_gap_analysis_error'));
            } finally {
                setLoading(false);
            }
        };

        if (location.state) {
            if (location.state.data) {
                setSkillAnalysisData(location.state.data.skill_analysis);
            } else if (location.state.type && location.state.profileId) {
                triggerSkillGapAnalysis(location.state.profileId, location.state.type);
            } else {
                console.log("No relevant state data found");
            }
        } else {
            navigate('/login');
        }
    }, [location, navigate, t]);

    // Extracting data with null checks
    const {
        current_job_role: currentJobRole = '',
        desired_job_role: desiredJobRole = '',
        candidate_name: candidateName = '',
        summary = '',
        skill_gaps: skillGaps = { technical_skills_competency: [], critical_core_skills: [] },
        career_development_plan: careerDevelopmentPlan = [],
        recommended_courses: recommendedCourses = [],
        skills_score: skillsScore = 10  // Add skills_score here
    } = skillAnalysisData || {};

    const proficiencyToPercentTechnical = (level) => proficiencyLevelsTechnical[level] || 0;
    const proficiencyToPercentSoft = (level) => proficiencyLevelsSoft[level] || 0;

    const radarData = skillGaps.technical_skills_competency.concat(skillGaps.critical_core_skills).map((gap) => {
        return {
            subject: gap.Skill,
            A: isNaN(parseInt(gap.CurrentProficiencyLevel))
                ? proficiencyToPercentSoft(gap.CurrentProficiencyLevel)
                : proficiencyToPercentTechnical(parseInt(gap.CurrentProficiencyLevel)),
            B: isNaN(parseInt(gap.ProficiencyLevelNeeded))
                ? proficiencyToPercentSoft(gap.ProficiencyLevelNeeded)
                : proficiencyToPercentTechnical(parseInt(gap.ProficiencyLevelNeeded)),
            fullMark: 100,
        };
    });

    const technicalSkills = skillGaps.technical_skills_competency.map((skill) => ({
        ...skill,
        currentProficiencyLevel: skill.CurrentProficiencyLevel,
        ProficiencyLevel: skill.ProficiencyLevelNeeded,
    }));

    const criticalCoreSkills = skillGaps.critical_core_skills.map((skill) => ({
        ...skill,
        currentProficiencyLevel: skill.CurrentProficiencyLevel,
        ProficiencyLevel: skill.ProficiencyLevelNeeded,
    }));

    const handleToggleCompletion = (item) => {
        setCompletedItems((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(item)) {
                newSet.delete(item);
            } else {
                newSet.add(item);
            }
            return newSet;
        });
    };

    const renderSkillBar = (skill) => {
        const requiredPercent = isNaN(parseInt(skill.ProficiencyLevel))
            ? proficiencyToPercentSoft(skill.ProficiencyLevel)
            : proficiencyToPercentTechnical(parseInt(skill.ProficiencyLevel));
        const currentPercent = isNaN(parseInt(skill.currentProficiencyLevel))
            ? proficiencyToPercentSoft(skill.currentProficiencyLevel)
            : proficiencyToPercentTechnical(parseInt(skill.currentProficiencyLevel));
        return (
            <Tooltip key={skill.Skill}
                     title={`${t('required')}: ${requiredPercent}%, ${t('current')}: ${currentPercent}%`}>
                <div className="skill-bar-container">
                    <Paragraph>
                        <b>{skill.Skill}</b> - {`${t('required')}: ${requiredPercent}%`}
                    </Paragraph>
                    <Progress
                        percent={requiredPercent}
                        success={{ percent: currentPercent, strokeWidth: 6 }}
                        showInfo={false}
                        strokeWidth={6}
                        strokeColor={requiredPercent > currentPercent ? '#ffbb33' : '#108ee9'}
                    />
                </div>
            </Tooltip>
        );
    };

    const renderPolarAngleAxisTick = ({ payload, x, y }) => {
        // Determine the text anchor based on the x coordinate
        const textAnchor = x > 745 ? 'start' : (x < 700 ? 'end' : 'middle');
        const dy = (x <= 744 && x > 722) ? -4 : 4;
        return (
            <text
                x={x}
                y={y}
                textAnchor={textAnchor}
                fill="#666"
                dy={dy}
                fontSize="10"
            >
                {payload.value}
            </text>
        );
    };

    const renderRecommendedCourses = () => {
        const rows = [];
        const cardsPerRow = 4; // Number of cards per row

        for (let i = 0; i < recommendedCourses.length; i += cardsPerRow) {
            const remainingCards = recommendedCourses.length - i;
            const courseItems = recommendedCourses.slice(i, i + cardsPerRow).map((course, index) => (
                <Col
                    key={index}
                    span={remainingCards < cardsPerRow ? 24 / remainingCards : 24 / cardsPerRow} // Adjust span for the last row
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Card
                        hoverable
                        style={{ width: 260, marginLeft: 20, marginBottom: 20 }}
                        cover={
                            <img
                                alt={course.course_title}
                                src={`https://picsum.photos/240/140?nocache=${Date.now()}&text=${encodeURIComponent(course.course_title)}`}
                            />
                        }
                    >
                        <Card.Meta title={course.course_title} description={course.course_description} />
                    </Card>
                </Col>
            ));
            rows.push(<Row gutter={16} key={i}>{courseItems}</Row>);
        }
        return rows;
    };

    // Determine the card title based on the type
    let cardTitle = '';
    if (location.state && location.state.type === 'current') {
        cardTitle = t('Your Current Role Skills Scorecard');
    } else if (location.state && location.state.type === 'desired') {
        cardTitle = t('Your Desired Role Skills Scorecard');
    } else if (location.state && location.state.analysis === 'desired') {
        cardTitle = `${candidateName}'s ${t('Desired Role Skills Scorecard')}`;
    } else {
        cardTitle = `${candidateName} ${t('Current Role Skills Scorecard')}`;
    }

    // Compute counts for technical skills, critical core skills, and career development plan items
    const technicalSkillsCount = technicalSkills.length;
    const criticalCoreSkillsCount = criticalCoreSkills.length;
    const careerDevelopmentPlanCount = careerDevelopmentPlan.length;

    // Show loading indicator if loading
    if (loading) {
        return <Spin tip={t('loading')} />;
    }

    // Determine the skills score indicator
    const { text: scoreText, color: scoreColor } = getSkillsScoreIndicator(skillsScore);

    return (
        <Spin spinning={loading}>
            <div>
                <Card title={cardTitle}>
                    <div className="skill-gap-container">
                        <Card style={{ marginBottom: 20 }}>
                            <Row align="top">
                                <Col span={3} style={{ textAlign: 'center' }}>
                                    <Title level={5}>{t('Skills Score')}</Title>
                                    <Progress
                                        type="circle"
                                        percent={skillsScore}
                                        format={() => skillsScore}
                                        strokeColor={scoreColor}
                                        width={80}
                                    />
                                    <Text style={{ color: scoreColor, fontWeight: 'bold', display: 'block', marginTop: 8 }}>
                                        {scoreText}
                                    </Text>
                                </Col>
                                <Col span={7}>
                                    <Title level={5}>{t('current_job_role')}</Title>
                                    <Text>{currentJobRole}</Text>
                                    {location.state && (location.state.type === 'desired' || location.state.analysis === 'desired') && (
                                        <>
                                            <Title level={5} style={{ marginTop: 16 }}>{t('desired_job_role')}</Title>
                                            <Text>{desiredJobRole}</Text>
                                        </>
                                    )}
                                </Col>
                                <Col span={14}>
                                    <Title level={5}>{t('summary')}</Title>
                                    <Paragraph>{summary}</Paragraph>
                                </Col>
                            </Row>
                        </Card>
                        <Tabs defaultActiveKey="1" type="card">
                            <TabPane tab={<span>{t('skills_overview')}</span>} key="1">
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <ResponsiveContainer width="100%" height={500}>
                                            <RadarChart outerRadius={200} data={radarData}>
                                                <PolarGrid />
                                                <PolarAngleAxis dataKey="subject" tick={renderPolarAngleAxisTick} />
                                                <PolarRadiusAxis angle={30} domain={[0, 100]} />
                                                <Radar name={t('current')} dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                                                <Radar name={t('required')} dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                                            </RadarChart>
                                        </ResponsiveContainer>
                                    </Col>
                                </Row>
                                <Row gutter={16} style={{ marginTop: 20 }}>
                                    <Col span={12}>
                                        <Title level={2}>{`${t('technical_skills')} (${technicalSkillsCount})`}</Title>
                                        {technicalSkills.map((skill) => renderSkillBar(skill))}
                                    </Col>
                                    <Col span={12}>
                                        <Title level={2}>{`${t('critical_core_skills')} (${criticalCoreSkillsCount})`}</Title>
                                        {criticalCoreSkills.map((skill) => renderSkillBar(skill))}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab={`${t('career_development_plan')}`} key="2">
                                <Title level={2}>{`${t('career_development_plan')} (${careerDevelopmentPlanCount})`}</Title>
                                <List
                                    dataSource={careerDevelopmentPlan}
                                    renderItem={(item, index) => (
                                        <List.Item style={{ border: 'none', padding: '10px' }}>
                                            <Checkbox checked={completedItems.has(item)} onChange={() => handleToggleCompletion(item)}>
                                                {index + 1}. {item}
                                            </Checkbox>
                                        </List.Item>
                                    )}
                                    bordered={false}
                                    split={false}
                                />
                            </TabPane>
                            <TabPane tab={t('Recommended Courses')} key="3">
                                <Title level={2}>{t('Recommended Courses')}</Title>
                                <Row gutter={16}>
                                    {renderRecommendedCourses()}
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </Card>
            </div>
        </Spin>
    );
};

export default SkillGap;
