import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Collapse, Input, Tag, Button } from 'antd';
import '../styles/App.scss';
import { DeleteOutlined } from "@ant-design/icons"; // Assuming your custom SCSS for additional styling is correctly linked
import { useTranslation } from 'react-i18next';

function ReviewAssessmentQuestions() {
    const { t } = useTranslation();
    const location = useLocation();
    // Initialize assessments state as an empty array to ensure .map can always be called on it
    const [assessments, setAssessments] = useState([]);

    // useEffect to set assessments data from location.state only when it's available and in the expected format
    useEffect(() => {
        console.log("Location state:", location.state);
        if (location.state && Array.isArray(location.state.data)) {
            setAssessments(location.state.data);
        } else {
            console.error("No data passed in location.state");
        }
    }, [location.state]);

    const handleDeleteQuestion = (assessmentId, questionId) => {
        const updatedAssessments = assessments.map((assessment) => {
            if (assessment.id === assessmentId) {
                return {
                    ...assessment,
                    questions: assessment.questions.filter((question) => question.id !== questionId),
                };
            }
            return assessment;
        });
        setAssessments(updatedAssessments);
    };

    const handleEditQuestionDetail = (assessmentId, questionId, field, newValue) => {
        const updatedAssessments = assessments.map((assessment) => {
            if (assessment.id === assessmentId) {
                return {
                    ...assessment,
                    questions: assessment.questions.map((question) => {
                        if (question.id === questionId) {
                            return { ...question, [field]: newValue };
                        }
                        return question;
                    }),
                };
            }
            return assessment;
        });
        setAssessments(updatedAssessments);
    };

    return (
        <div className="review-container">
            <h1>{t('review_assessment_questions')}</h1>
            <Collapse defaultActiveKey={assessments[0]?.id} accordion>
                {assessments.map((assessment) => (
                    <Collapse.Panel header={`${t('assessment')}: ${assessment.job_role_name} - ${assessment.skill_name}`} key={assessment.id}>
                        {assessment.questions.map((question) => (
                            <QuestionEdit
                                key={question.id}
                                question={question}
                                onDetailChange={handleEditQuestionDetail}
                                deleteQuestion={handleDeleteQuestion}
                                assessmentId={assessment.id}
                            />
                        ))}
                    </Collapse.Panel>
                ))}
            </Collapse>
        </div>
    );
}

function QuestionEdit({ question, onDetailChange, assessmentId, deleteQuestion }) {
    const { t } = useTranslation();
    const [editState, setEditState] = useState({ text: false, choices: question.choices.map(() => false) });

    const toggleEditState = (field, index = null) => {
        if (field === 'text') {
            setEditState({ ...editState, text: !editState.text });
        } else if (field === 'choices') {
            const newChoicesEditState = editState.choices.slice();
            if (index !== null) newChoicesEditState[index] = !newChoicesEditState[index];
            setEditState({ ...editState, choices: newChoicesEditState });
        }
    };

    const handleChoiceChange = (index, newChoice) => {
        const newChoices = [...question.choices];
        newChoices[index] = newChoice;
        onDetailChange(assessmentId, question.id, 'choices', newChoices);
    };

    return (
        <Card style={{ margin: '10px 0' }}>
            <div>
                {editState.text ? (
                    <Input
                        defaultValue={question.text}
                        onBlur={(e) => {
                            onDetailChange(assessmentId, question.id, 'text', e.target.value);
                            toggleEditState('text');
                        }}
                        autoFocus
                    />
                ) : (
                    <p onDoubleClick={() => toggleEditState('text')}>{question.text}</p>
                )}
            </div>
            <div>
                {question.choices.map((choice, index) => (
                    <Tag
                        key={index}
                        closable
                        onClose={() => handleChoiceChange(index, '')}
                        color={question.answers.includes(choice) ? 'green' : undefined}
                    >
                        {editState.choices[index] ? (
                            <Input
                                defaultValue={choice}
                                onBlur={(e) => {
                                    handleChoiceChange(index, e.target.value);
                                    toggleEditState('choices', index);
                                }}
                                autoFocus
                            />
                        ) : (
                            <span onDoubleClick={() => toggleEditState('choices', index)}>{choice}</span>
                        )}
                    </Tag>
                ))}
            </div>
            <div className="review">
                <div className="innerText"><b>{t('explanation')}:</b> {question.explanation}<br/></div>
                <div className="innerText"><b>{t('complexity')}:</b> {question.complexity}</div>
            </div>
            <Button
                style={{ position: 'absolute', top: 0, right: 0 }}
                type="primary"
                danger
                icon={<DeleteOutlined />}  // delete or trash icon
                onClick={() => deleteQuestion(assessmentId, question.id)}
            >
            </Button>
        </Card>
    );
}

export default ReviewAssessmentQuestions;
