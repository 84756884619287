// src/i18n/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend) // Use the Backend plugin for loading translations
    .use(LanguageDetector) // Use LanguageDetector for automatic language detection
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        backend: {
            // Path where resources load from, serving the files from the public folder in this case
            loadPath: './locales/{{lng}}.json',
        },
        fallbackLng: "en", // Fallback language is English
        debug: false, // Set to true if you want to see debug logs
        detection: {
            // Order and from where user language should be detected
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            // Keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            // Cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // Languages to not persist (cimode is a special mode for pseudo-localization)
        },
        interpolation: {
            escapeValue: false, // React already safes from xss
        },
    });

export default i18n;
