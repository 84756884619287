import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Spin, Row, Col, Table, Select, Button, Modal, Form, Input, Upload, message, Dropdown, Menu } from 'antd';
import { UploadOutlined, MoreOutlined, CopyOutlined, FilterOutlined, DatabaseOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../config';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const FrameworkDetails = () => {
    const { id: framework_id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [form] = Form.useForm();
    const [filteredJobRoles, setFilteredJobRoles] = useState([]);
    const [filteredCompetencies, setFilteredCompetencies] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [selectedJobRole, setSelectedJobRole] = useState(null);
    const [selectedCompetency, setSelectedCompetency] = useState(null);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 30, total: 0 });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [copyModalVisible, setCopyModalVisible] = useState(false);
    const [targetFramework, setTargetFramework] = useState(null);
    const [frameworks, setFrameworks] = useState([]);

    useEffect(() => {
        fetchData();
        fetchFrameworks();
    }, [framework_id]);

    const fetchData = () => {
        setLoading(true);
        let url = `${config.sectorNestedDetailsUrl}`;
        if (framework_id) {
            url += `&framework_id=${framework_id}`;
        }

        axios.get(url)
            .then(response => {
                const transformedData = transformData(response.data);
                setData(transformedData);
                setFilteredData(transformedData);
                setPagination({ ...pagination, total: transformedData.length });
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    };

    const fetchFrameworks = () => {
        axios.get(config.frameworkUrl)
            .then(response => {
                setFrameworks(response.data);
            })
            .catch(error => {
                console.error("Error fetching frameworks:", error);
            });
    };

    const transformData = (data) => {
        let transformedData = [];
        data.forEach(sector => {
            sector.job_roles.forEach(jobRole => {
                jobRole.competencies.forEach(comp => {
                    transformedData.push({
                        key: `${sector.name}-${jobRole.name}-${comp.name}`, // Unique key for each row
                        industry: sector.name,
                        track: jobRole.track,
                        jobRole: jobRole.name,
                        competency: comp.name,
                        proficiency: comp.proficiency
                    });
                });
            });
        });
        return transformedData;
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
    };

    const columns = [
        {
            title: t('Industry'),
            dataIndex: 'industry',
            key: 'industry',
            sorter: (a, b) => a.industry.localeCompare(b.industry),
        },
        {
            title: t('Track'),
            dataIndex: 'track',
            key: 'track',
            sorter: (a, b) => a.track.localeCompare(b.track),
        },
        {
            title: t('Job Role'),
            dataIndex: 'jobRole',
            key: 'jobRole',
            sorter: (a, b) => a.jobRole.localeCompare(b.jobRole),
        },
        {
            title: t('Competency/Skill'),
            dataIndex: 'competency',
            key: 'competency',
            sorter: (a, b) => a.competency.localeCompare(b.competency),
        },
        {
            title: t('Proficiency Level'),
            dataIndex: 'proficiency',
            key: 'proficiency',
            sorter: (a, b) => a.proficiency - b.proficiency,
        },
        {
            title: t('Actions'),
            key: 'actions',
            render: (text, record) => (
                <Dropdown overlay={menu(record)} trigger={['click']}>
                    <Button icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    const menu = (record) => (
        <Menu>
            <Menu.Item key="edit" onClick={() => handleEdit(record)}>
                {t('Edit')}
            </Menu.Item>
            <Menu.Item key="generateAssessment" onClick={() => handleGenerateAssessment(record)}>
                {t('Generate Assessment')}
            </Menu.Item>
            <Menu.Item key="viewAssessment" onClick={() => handleViewAssessment(record)}>
                {t('View Assessment Questions')}
            </Menu.Item>
        </Menu>
    );

    const handleEdit = (record) => {
        setEditItem(record);
        setModalVisible(true);
        form.setFieldsValue(record);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditItem(null);
    };

    const handleSave = (values) => {
        const newData = data.map(item => {
            if (item.industry === editItem.industry && item.jobRole === editItem.jobRole && item.competency === editItem.competency) {
                return { ...item, ...values };
            }
            return item;
        });
        setData(newData);
        setFilteredData(newData);
        setModalVisible(false);
        setEditItem(null);
    };

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const handleFilterChange = (value, filterKey) => {
        let filtered = data;

        if (filterKey === 'industry') {
            setSelectedIndustry(value);
            setSelectedJobRole(null);
            setSelectedCompetency(null);
            filtered = data.filter(item => item.industry === value);
            const jobRoles = [...new Set(filtered.map(item => item.jobRole))];
            setFilteredJobRoles(jobRoles);
            setFilteredCompetencies([]);
        } else if (filterKey === 'jobRole') {
            setSelectedJobRole(value);
            setSelectedCompetency(null);
            filtered = data.filter(item => item.industry === selectedIndustry && item.jobRole === value);
            const competencies = [...new Set(filtered.map(item => item.competency))];
            setFilteredCompetencies(competencies);
        } else if (filterKey === 'competency') {
            setSelectedCompetency(value);
            filtered = data.filter(item => item.industry === selectedIndustry && item.jobRole === selectedJobRole && item.competency === value);
        }

        setFilteredData(filtered);
        setPagination({ ...pagination, current: 1, total: filtered.length });
    };

    const handleResetFilters = () => {
        setFilteredData(data);
        setFilteredJobRoles([]);
        setFilteredCompetencies([]);
        setSelectedIndustry(null);
        setSelectedJobRole(null);
        setSelectedCompetency(null);
        setPagination({ ...pagination, current: 1, total: data.length });
    };

    const handleUpload = ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('file', file);
        if (framework_id) {
            formData.append('framework_id', framework_id);
        }

        axios.post(config.uploadFrameworkDetailsUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            timeout: 120 * 60 * 1000 // 120 minutes
        }).then(() => {
            onSuccess(null, file);
            message.success(t('Upload successful'));
            setUploadModalVisible(false);
            fetchData();
        }).catch(() => {
            onError();
            message.error(t('Upload failed'));
        });
    };

    const handleGenerateAssessment = (record) => {
        const { industry, track, jobRole, competency, proficiency } = record;
        navigate('/generate', {
            state: { industry, track, jobRole, competency, proficiency }
        });
    };

    const handleViewAssessment = (record) => {
        const { industry, track, jobRole, competency, proficiency } = record;
        navigate('/list', {
            state: { industry, track, jobRole, competency, proficiency }
        });
    };

    const handleCopySelected = () => {
        setCopyModalVisible(true);
    };

    const handleCopy = () => {
        if (!targetFramework) {
            message.error(t('Please select a target framework.'));
            return;
        }

        const copiedItems = selectedRowKeys.map(key => {
            const row = data.find(item => item.key === key);
            return { ...row, framework_id: targetFramework };
        });

        axios.post(config.copyToFrameworkUrl, copiedItems)
            .then(() => {
                message.success(t('Items copied successfully'));
                setCopyModalVisible(false);
                setSelectedRowKeys([]); // Clear selected rows after copying
            })
            .catch(() => {
                message.error(t('Copy and training is queued. You will receive email notification upon completion.'));
            });
    };

    const handleSaveToVectorDB = () => {
        if (!framework_id || !selectedIndustry) {
            message.error(t('Please ensure all necessary filters are selected.'));
            return;
        }

        // Prepare the payload
        const payload = {
            framework_id,
            industry: selectedIndustry,
            track: selectedJobRole ? data.find(item => item.jobRole === selectedJobRole).track : null,
            jobRole: selectedJobRole || null
        };

        // Remove null values from payload
        Object.keys(payload).forEach(key => payload[key] === null && delete payload[key]);

        axios.post(config.saveToVectorDBUrl, payload)
            .then(() => {
                message.success(t('Data successfully saved to Vector DB.'));
            })
            .catch(() => {
                message.error(t('Failed to save data to Vector DB.'));
            });
    };

    return (
        <Spin spinning={loading}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card title={t('Framework Details')}>
                        <Row gutter={16} style={{ marginBottom: 16 }}>
                            <Col span={6}>
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder={t('filter_by_industry')}
                                    optionFilterProp="children"
                                    onChange={(value) => handleFilterChange(value, 'industry')}
                                    value={selectedIndustry}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {data && [...new Set(data.map(item => item.industry))].map(industry => (
                                        <Option key={industry} value={industry}>{industry}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={6}>
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder={t('filter_by_job_role')}
                                    optionFilterProp="children"
                                    onChange={(value) => handleFilterChange(value, 'jobRole')}
                                    value={selectedJobRole}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!filteredJobRoles.length}
                                >
                                    {filteredJobRoles.map(jobRole => (
                                        <Option key={jobRole} value={jobRole}>{jobRole}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={6}>
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder={t('filter_by_competency')}
                                    optionFilterProp="children"
                                    onChange={(value) => handleFilterChange(value, 'competency')}
                                    value={selectedCompetency}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!filteredCompetencies.length}
                                >
                                    {filteredCompetencies.map(competency => (
                                        <Option key={competency} value={competency}>{competency}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={1}>
                                <Button onClick={handleResetFilters} icon={<FilterOutlined />} style={{ width: '100%' }} />
                            </Col>
                            <Col span={1}>
                                <Button type="primary" icon={<UploadOutlined />} onClick={() => setUploadModalVisible(true)} style={{ width: '100%' }} />
                            </Col>
                            <Col span={1}>
                                <Button type="primary" icon={<CopyOutlined />} onClick={handleCopySelected} style={{ width: '100%' }} />
                            </Col>
                            <Col span={1}>
                                <Button type="primary" icon={<DatabaseOutlined />} onClick={handleSaveToVectorDB} style={{ width: '100%' }} />
                            </Col>
                        </Row>
                        <Table
                            columns={columns}
                            dataSource={filteredData.slice((pagination.current - 1) * pagination.pageSize, pagination.current * pagination.pageSize)}
                            rowSelection={rowSelection}
                            onChange={handleTableChange}
                            rowKey={(record, index) => `${index}-${record.industry}-${record.jobRole}-${record.competency}`}
                            pagination={{
                                current: pagination.current,
                                pageSize: pagination.pageSize,
                                total: pagination.total,
                                onChange: (page, pageSize) => setPagination({ current: page, pageSize })
                            }}
                        />
                    </Card>
                </Col>
            </Row>
            <Modal
                title={t('copy_selected_items')}
                visible={copyModalVisible}
                onCancel={() => setCopyModalVisible(false)}
                onOk={handleCopy}
                width={540}
            >
                <Form layout="vertical">
                    <Form.Item label={t('target_framework')}>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder={t('select_framework')}
                            optionFilterProp="children"
                            onChange={setTargetFramework}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {frameworks.map(fw => (
                                <Option key={fw.id} value={fw.id}>{fw.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={editItem ? t('Edit Item') : t('Add Item')}
                visible={modalVisible}
                onCancel={handleCancel}
                width={540}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        {t('Cancel')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => form.submit()}>
                        {t('Save')}
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSave}
                >
                    <Form.Item name="industry" label={t('Industry')}>
                        <Select>
                            {data && [...new Set(data.map(item => item.industry))].map(industry => (
                                <Option key={industry} value={industry}>{industry}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="jobRole" label={t('Job Role')}>
                        <Select>
                            {data && [...new Set(data.map(item => item.jobRole))].map(jobRole => (
                                <Option key={jobRole} value={jobRole}>{jobRole}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="competency" label={t('Competency/Skill')}>
                        <Select>
                            {data && [...new Set(data.map(item => item.competency))].map(competency => (
                                <Option key={competency} value={competency}>{competency}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="proficiency" label={t('Proficiency Level')}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={t('Upload Framework Details')}
                visible={uploadModalVisible}
                onCancel={() => setUploadModalVisible(false)}
                footer={null}
            >
                <Upload.Dragger
                    name="file"
                    multiple={false}
                    customRequest={handleUpload}
                >
                    <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">{t('Drag and drop a file here or click to browse')}</p>
                </Upload.Dragger>
            </Modal>
        </Spin>
    );
};

export default FrameworkDetails;
