import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Form, Input, message, Modal, Spin } from 'antd';
import {
    PlusOutlined,
    DatabaseOutlined,
    AppstoreOutlined,
    OneToOneOutlined,
    GroupOutlined
} from '@ant-design/icons';
import config from "../config";
import { useTranslation } from 'react-i18next';

const Framework = () => {
    const [frameworks, setFrameworks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        fetchFrameworks();
    }, [i18n.language]);

    const fetchFrameworks = async () => {
        try {
            const response = await axios.get(config.frameworkUrl);
            if (Array.isArray(response.data)) {
                setFrameworks(response.data);
            } else {
                setFrameworks([]);
                message.error(t('Error: Data format is incorrect'));
            }
            setLoading(false);
        } catch (error) {
            message.error(t('Error fetching frameworks'));
            setLoading(false);
        }
    };

    const showAddFrameworkModal = () => {
        setIsModalVisible(true);
    };

    const handleAddFramework = async (values) => {
        try {
            await axios.post(config.addFrameworkUrl, values);
            message.success(t('Framework added successfully'));
            setIsModalVisible(false);
            form.resetFields();
            fetchFrameworks(); // Refresh the list
        } catch (error) {
            message.error(t('Error adding framework'));
        }
    };

    const handleTileClick = (id) => {
        if (id) {
            navigate(`/framework-details/${id}`);
        } else {
            message.error(t('Error loading framework details'));
        }
    };

    const icons = [
        <DatabaseOutlined key="database" style={{ fontSize: '24px', color: '#ff4d4f', position: 'absolute', top: '20px', left: '20px' }} />,
        <AppstoreOutlined key="appstore" style={{ fontSize: '24px', color: '#52c41a', position: 'absolute', top: '20px', left: '20px' }} />,
        <OneToOneOutlined key="bug" style={{ fontSize: '24px', color: '#faad14', position: 'absolute', top: '20px', left: '20px' }} />,
        <GroupOutlined key="code" style={{ fontSize: '24px', color: '#722ed1', position: 'absolute', top: '20px', left: '20px' }} />
    ];

    return (
        <Spin spinning={loading}>
            <div>
                <Card title={t('frameworks')} style={{ width: '100%', minHeight: '100vh' }}>
                    <div className="framework-tiles-container" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        height: '60vh'
                    }}>
                        {frameworks.map((framework, index) => (
                            <Card
                                key={framework.id || framework.name}
                                className="framework-tile"
                                hoverable
                                onClick={() => handleTileClick(framework.id)}
                                style={{
                                    width: 200,
                                    height: 150,
                                    margin: 10,
                                    textAlign: 'left',
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between', // Space between icon and text
                                    padding: '20px'
                                }}
                            >
                                {React.cloneElement(icons[index % icons.length], { key: `icon-${framework.id || framework.name}` })}
                                <div style={{
                                    position: 'absolute',
                                    bottom: '20px',
                                    left: '20px',
                                    height: '60px', // Fixed height for text container
                                    display: 'flex',
                                    alignItems: 'flex-start' // Align text to the top of the container
                                }}>
                                    <p style={{ margin: 0 }}>{framework.name}</p>
                                </div>
                            </Card>
                        ))}
                        <Card
                            onClick={showAddFrameworkModal}
                            className="framework-tile add-framework-tile"
                            style={{
                                width: 200,
                                height: 150,
                                margin: 10,
                                textAlign: 'left',
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <PlusOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                        </Card>
                    </div>

                    <Modal
                        title={t('Add New Framework')}
                        open={isModalVisible}
                        onCancel={() => setIsModalVisible(false)}
                        footer={null}
                    >
                        <Form form={form} onFinish={handleAddFramework}>
                            <Form.Item
                                name="name"
                                label={t('Framework Name')}
                                rules={[{ required: true, message: t('Please input the framework name!') }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    {t('Add Framework')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Card>
            </div>
        </Spin>
    );
};

export default Framework;
