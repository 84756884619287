import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import {Table, Select, Button, Card, Spin} from 'antd';
import config from '../config';
import { useTranslation } from "react-i18next";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const fileName = 'Assessments';

const exportToCSV = (data, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    data.sort((a, b) => {
        if (a.job_role_name === b.job_role_name) {
            return a.skill_name < b.skill_name ? -1 : 1;
        }
        return a.job_role_name < b.job_role_name ? -1 : 1;
    });

    let flatData = [];

    data.forEach(item => {
        item.questions.forEach(question => {
            const choices = question.choices
                .map((choice, index) =>
                    `(${String.fromCharCode(97 + index)}) ${choice}`
                )
                .join(', ');

            flatData.push({
                job_role: item.job_role_name,
                skills: item.skill_name,
                question_id: question.id,
                question_text: question.text,
                choices: choices,
                answers: question.answers,
                answers_explanation: question.explanation,
                question_complexity: question.complexity
            });
        });
    });

    const ws = XLSX.utils.json_to_sheet(flatData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataFile = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataFile, fileName + fileExtension);
}

const AssessmentListingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [sectorSelect, setSectorSelect] = useState([]);
    const [jobRoleSelect, setJobRoleSelect] = useState([]);
    const [skillsSelect, setSkillsSelect] = useState([]);
    const [selectedSector, setSelectedSector] = useState(undefined);
    const [selectedJobRole, setSelectedJobRole] = useState(undefined);
    const [selectedSkills, setSelectedSkills] = useState(undefined);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(config.assessmentsListURL);

            setData(result.data);
            setFilteredData(result.data);

            const sectors = [...new Set(result.data.map(item => item.sector_name))];
            const jobRoles = [...new Set(result.data.map(item => item.job_role_name))];
            const skills = [...new Set(result.data.map(item => item.skill_name))];

            setSectorSelect(sectors.map(sector => ({ label: sector, value: sector })));
            setJobRoleSelect(jobRoles.map(role => ({ label: role, value: role })));
            setSkillsSelect(skills.map(skill => ({ label: skill, value: skill })));
            setLoading(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (location.state) {
            const { industry, jobRole, competency } = location.state;
            setSelectedSector(industry);
            setSelectedJobRole(jobRole);
            setSelectedSkills(competency);

            const filtered = data.filter(item =>
                item.sector_name === industry &&
                item.job_role_name === jobRole &&
                item.skill_name === competency
            );
            setFilteredData(filtered);
        }
    }, [location.state, data]);

    const nestedColumns = [
        { title: t('question_text'), dataIndex: 'text', key: 'text', className: 'question-text-column' },
        { title: t('choices'), dataIndex: 'choices', key: 'choices', className: 'choices-column', render: choices => choices.join(', ') },
        { title: t('explanation'), dataIndex: 'explanation', key: 'explanation', className: 'explanation-column' },
        { title: t('complexity'), dataIndex: 'complexity', key: 'complexity', className: 'complexity-column' }
    ];

    const handleJobRoleClick = (jobRole) => {
        const relatedRecords = data.filter(item => item.job_role_name === jobRole);
        navigate('/review-assessment-questions', { state: { data: relatedRecords } });
    };

    const columns = [
        {
            title: t('job_role'), dataIndex: 'job_role_name', key: 'job_role_name', className: 'job-role-column',
            render: (text, record) => {
                return (
                    <Button type="link" onClick={() => handleJobRoleClick(record.job_role_name)}>
                        {text}
                    </Button>
                );
            },
        },
        {
            title: t('skills'), dataIndex: 'skill_name', key: 'skill_name', className: 'skills-column',
            render: (text, record) => {
                return (
                    <Button type="link" onClick={() => handleJobRoleClick(record.job_role_name)}>
                        {text}
                    </Button>
                );
            },
        },
        {
            title: t('questions'), dataIndex: 'questions', key: 'questions', className: 'questions-column',
            render: (text, record, index) =>
                <Table className="inner-data-table"
                       rowKey={record => record.id}
                       columns={nestedColumns}
                       dataSource={record.questions}
                       pagination={false}
                       rowClassName={(innerRecord, innerIndex) =>
                           index % 2 === 0
                               ? (innerIndex % 2 === 0 ? 'inner-row-even' : 'inner-row-odd')
                               : (innerIndex % 2 === 0 ? 'inner-row-odd' : 'inner-row-even')
                       }
                />
        },
    ];

    const handleFilterChange = (value, columnName) => {
        let newFilter;
        let updatedSectorSelect = sectorSelect;
        let updatedJobRoleSelect = jobRoleSelect;
        let updatedSkillsSelect = skillsSelect;

        if (columnName === 'sector_name') {
            setSelectedSector(value);
            newFilter = data.filter(row => row[columnName] === value);

            const applicableJobRoles = [...new Set(newFilter.map(item => item.job_role_name))];
            const applicableSkills = [...new Set(newFilter.map(item => item.skill_name))];

            updatedJobRoleSelect = applicableJobRoles.map(role => ({ label: role, value: role }));
            updatedSkillsSelect = applicableSkills.map(skill => ({ label: skill, value: skill }));
        } else if (columnName === 'job_role_name') {
            setSelectedJobRole(value);
            newFilter = data.filter(row => row[columnName] === value);

            const applicableSectors = [...new Set(newFilter.map(item => item.sector_name))];
            const applicableSkills = [...new Set(newFilter.map(item => item.skill_name))];

            updatedSectorSelect = applicableSectors.map(sector => ({ label: sector, value: sector }));
            updatedSkillsSelect = applicableSkills.map(skill => ({ label: skill, value: skill }));
        } else {
            setSelectedSkills(value);
            newFilter = data.filter(row => row[columnName] === value);

            const applicableSectors = [...new Set(newFilter.map(item => item.sector_name))];
            const applicableJobRoles = [...new Set(newFilter.map(item => item.job_role_name))];

            updatedSectorSelect = applicableSectors.map(sector => ({ label: sector, value: sector }));
            updatedJobRoleSelect = applicableJobRoles.map(role => ({ label: role, value: role }));
        }

        setSectorSelect(updatedSectorSelect);
        setJobRoleSelect(updatedJobRoleSelect);
        setSkillsSelect(updatedSkillsSelect);
        setFilteredData(newFilter);
    };

    const handleResetFilter = () => {
        setFilteredData(data);
        setSelectedSector(undefined);
        setSelectedJobRole(undefined);
        setSelectedSkills(undefined);

        const sectors = [...new Set(data.map(item => item.sector_name))];
        const jobRoles = [...new Set(data.map(item => item.job_role_name))];
        const skills = [...new Set(data.map(item => item.skill_name))];

        setSectorSelect(sectors.map(sector => ({ label: sector, value: sector })));
        setJobRoleSelect(jobRoles.map(role => ({ label: role, value: role })));
        setSkillsSelect(skills.map(skill => ({ label: skill, value: skill })));
    }

    return (
        <Spin spinning={loading}>
            <div>
                <Card title={t('list_assessment')}>
                <div className="list-assessment-container">
            <div>
                <Select
                    value={selectedSector}
                    className="sector-select"
                    onChange={(value) => handleFilterChange(value, 'sector_name')}
                    options={sectorSelect}
                    placeholder={t('select_sector')}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                />
                <Select
                    value={selectedJobRole}
                    className="job-role-select"
                    onChange={(value) => handleFilterChange(value, 'job_role_name')}
                    options={jobRoleSelect}
                    placeholder={t('select_job_role')}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                />
                <Select
                    value={selectedSkills}
                    className="skills-select"
                    onChange={(value) => handleFilterChange(value, 'skill_name')}
                    options={skillsSelect}
                    placeholder={t('select_skills')}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                />
                <Button className="reset-filter-btn" type="primary" onClick={handleResetFilter}>{t('reset_filter')}</Button>
                <Button className="export-btn" type="primary" onClick={() => exportToCSV(filteredData, fileName)}>{t('export_to_excel')}</Button>
                <Table className="outer-data-table"
                       columns={columns}
                       dataSource={filteredData}
                       rowClassName={(record, index) => index % 2 === 0 ? 'row-even' : 'row-odd'}
                />
            </div>
        </div>
                </Card>
            </div>
        </Spin>
    );
}

export default AssessmentListingPage;
