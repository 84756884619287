// CardContent.js
import React from 'react';
import { Row, Col, Button, Space, Tooltip, Typography, Divider, Spin } from 'antd';
import { LikeOutlined, DislikeOutlined, DeleteOutlined, ExpandOutlined, CompressOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

const CardContent = ({ card, handleVote, handleDelete, expandedImages, toggleImageExpansion, loadingCards, currentCardIndex }) => {
    const { t } = useTranslation();

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const components = {
        code({ inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
                <SyntaxHighlighter style={vscDarkPlus} language={match[1]} PreTag="div" {...props}>
                    {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        },
        img({ src, alt }) {
            const isExpanded = expandedImages[src] || false;
            return (
                <div className={`image-container ${isExpanded ? 'expanded' : ''}`}>
                    <img
                        src={src}
                        alt={alt}
                        className={`content-image ${isExpanded ? 'expanded' : ''}`}
                    />
                    <Button
                        icon={isExpanded ? <CompressOutlined /> : <ExpandOutlined />}
                        onClick={() => toggleImageExpansion(src)}
                        className="image-expand-button"
                    />
                </div>
            );
        }
    };

    const renderFiles = (files) => {
        if (!files || files.length === 0) return null;

        return files.map((file, index) => {
            if (file.file_type === 'image/png') {
                const isExpanded = expandedImages[file.file_url] || false;
                return (
                    <div key={index} className={`image-container ${isExpanded ? 'expanded' : ''}`}>
                        <img
                            src={file.file_url}
                            alt={`file-${index}`}
                            className={`content-image ${isExpanded ? 'expanded' : ''}`}
                        />
                        <Button
                            icon={isExpanded ? <CompressOutlined /> : <ExpandOutlined />}
                            onClick={() => toggleImageExpansion(file.file_url)}
                            className="image-expand-button"
                        />
                    </div>
                );
            }
            return (
                <Button key={index} type="link" href={file.file_url} download className="file-download-button">
                    {t('DOWNLOAD_FILE')}
                </Button>
            );
        });
    };

    return (
        <>
            <Row gutter={16} align="top">
                <Col span={19}>
                    <Paragraph ellipsis={{ rows: 2, expandable: false }}>
                        {capitalizeFirstLetter(card.title)}
                    </Paragraph>
                </Col>
                <Col span={5} style={{ textAlign: 'right' }}>
                    <Space>
                        <Tooltip title={t('GOOD_RESPONSE')}>
                            <Button
                                icon={<LikeOutlined style={{ color: card.feedback === 2 ? 'blue' : 'black' }} />}
                                onClick={() => handleVote(card.prompt_id, true)}
                            />
                        </Tooltip>
                        <Tooltip title={t('BAD_RESPONSE')}>
                            <Button
                                icon={<DislikeOutlined style={{ color: card.feedback === 1 ? 'blue' : 'black' }} />}
                                onClick={() => handleVote(card.prompt_id, false)}
                            />
                        </Tooltip>
                        <Tooltip title={t('DELETE_PROMPT')}>
                            <Button
                                icon={<DeleteOutlined />}
                                onClick={() => handleDelete(card.prompt_id)}
                            />
                        </Tooltip>
                    </Space>
                </Col>
            </Row>
            <Divider style={{ margin: '12px 0' }} />
            <div className="markdown-reset">
                <ReactMarkdown components={components}>
                    {card.content}
                </ReactMarkdown>
            </div>
            {renderFiles(card.files)}
            {loadingCards.has(currentCardIndex) && (
                <div className="loading-indicator">
                    <Spin size="large" />
                </div>
            )}
        </>
    );
};

export default CardContent;